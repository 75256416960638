/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TournamentRoundGroupMember
 */
export interface TournamentRoundGroupMember {
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    betCount: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    absolutePoints: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundGroupMember
     */
    absoluteRank: number;
}

/**
 * Check if a given object implements the TournamentRoundGroupMember interface.
 */
export function instanceOfTournamentRoundGroupMember(value: object): value is TournamentRoundGroupMember {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('betCount' in value) || value['betCount'] === undefined) return false;
    if (!('absolutePoints' in value) || value['absolutePoints'] === undefined) return false;
    if (!('absoluteRank' in value) || value['absoluteRank'] === undefined) return false;
    return true;
}

export function TournamentRoundGroupMemberFromJSON(json: any): TournamentRoundGroupMember {
    return TournamentRoundGroupMemberFromJSONTyped(json, false);
}

export function TournamentRoundGroupMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentRoundGroupMember {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'points': json['points'],
        'rank': json['rank'],
        'betCount': json['betCount'],
        'absolutePoints': json['absolutePoints'],
        'absoluteRank': json['absoluteRank'],
    };
}

export function TournamentRoundGroupMemberToJSON(value?: TournamentRoundGroupMember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'points': value['points'],
        'rank': value['rank'],
        'betCount': value['betCount'],
        'absolutePoints': value['absolutePoints'],
        'absoluteRank': value['absoluteRank'],
    };
}

