/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HighscoreStrategyDescriptor
 */
export interface HighscoreStrategyDescriptor {
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HighscoreStrategyDescriptor
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinTendencyMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinTendencyMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinDifferenceMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinDifferenceMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinResultMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    homeWinResultMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    drawTendencyMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    drawTendencyMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    drawResultMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    drawResultMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinTendencyMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinTendencyMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinDifferenceMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinDifferenceMax: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinResultMin: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreStrategyDescriptor
     */
    awayWinResultMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof HighscoreStrategyDescriptor
     */
    standardStrategy: boolean;
    /**
     * 
     * @type {string}
     * @memberof HighscoreStrategyDescriptor
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof HighscoreStrategyDescriptor
     */
    distinctByQuotes: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HighscoreStrategyDescriptor
     */
    distinctByHomeAway: boolean;
}

/**
 * Check if a given object implements the HighscoreStrategyDescriptor interface.
 */
export function instanceOfHighscoreStrategyDescriptor(value: object): value is HighscoreStrategyDescriptor {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('homeWinTendencyMin' in value) || value['homeWinTendencyMin'] === undefined) return false;
    if (!('homeWinTendencyMax' in value) || value['homeWinTendencyMax'] === undefined) return false;
    if (!('homeWinDifferenceMin' in value) || value['homeWinDifferenceMin'] === undefined) return false;
    if (!('homeWinDifferenceMax' in value) || value['homeWinDifferenceMax'] === undefined) return false;
    if (!('homeWinResultMin' in value) || value['homeWinResultMin'] === undefined) return false;
    if (!('homeWinResultMax' in value) || value['homeWinResultMax'] === undefined) return false;
    if (!('drawTendencyMin' in value) || value['drawTendencyMin'] === undefined) return false;
    if (!('drawTendencyMax' in value) || value['drawTendencyMax'] === undefined) return false;
    if (!('drawResultMin' in value) || value['drawResultMin'] === undefined) return false;
    if (!('drawResultMax' in value) || value['drawResultMax'] === undefined) return false;
    if (!('awayWinTendencyMin' in value) || value['awayWinTendencyMin'] === undefined) return false;
    if (!('awayWinTendencyMax' in value) || value['awayWinTendencyMax'] === undefined) return false;
    if (!('awayWinDifferenceMin' in value) || value['awayWinDifferenceMin'] === undefined) return false;
    if (!('awayWinDifferenceMax' in value) || value['awayWinDifferenceMax'] === undefined) return false;
    if (!('awayWinResultMin' in value) || value['awayWinResultMin'] === undefined) return false;
    if (!('awayWinResultMax' in value) || value['awayWinResultMax'] === undefined) return false;
    if (!('standardStrategy' in value) || value['standardStrategy'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('distinctByQuotes' in value) || value['distinctByQuotes'] === undefined) return false;
    if (!('distinctByHomeAway' in value) || value['distinctByHomeAway'] === undefined) return false;
    return true;
}

export function HighscoreStrategyDescriptorFromJSON(json: any): HighscoreStrategyDescriptor {
    return HighscoreStrategyDescriptorFromJSONTyped(json, false);
}

export function HighscoreStrategyDescriptorFromJSONTyped(json: any, ignoreDiscriminator: boolean): HighscoreStrategyDescriptor {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'homeWinTendencyMin': json['homeWinTendencyMin'],
        'homeWinTendencyMax': json['homeWinTendencyMax'],
        'homeWinDifferenceMin': json['homeWinDifferenceMin'],
        'homeWinDifferenceMax': json['homeWinDifferenceMax'],
        'homeWinResultMin': json['homeWinResultMin'],
        'homeWinResultMax': json['homeWinResultMax'],
        'drawTendencyMin': json['drawTendencyMin'],
        'drawTendencyMax': json['drawTendencyMax'],
        'drawResultMin': json['drawResultMin'],
        'drawResultMax': json['drawResultMax'],
        'awayWinTendencyMin': json['awayWinTendencyMin'],
        'awayWinTendencyMax': json['awayWinTendencyMax'],
        'awayWinDifferenceMin': json['awayWinDifferenceMin'],
        'awayWinDifferenceMax': json['awayWinDifferenceMax'],
        'awayWinResultMin': json['awayWinResultMin'],
        'awayWinResultMax': json['awayWinResultMax'],
        'standardStrategy': json['standardStrategy'],
        'description': json['description'],
        'distinctByQuotes': json['distinctByQuotes'],
        'distinctByHomeAway': json['distinctByHomeAway'],
    };
}

export function HighscoreStrategyDescriptorToJSON(value?: HighscoreStrategyDescriptor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'homeWinTendencyMin': value['homeWinTendencyMin'],
        'homeWinTendencyMax': value['homeWinTendencyMax'],
        'homeWinDifferenceMin': value['homeWinDifferenceMin'],
        'homeWinDifferenceMax': value['homeWinDifferenceMax'],
        'homeWinResultMin': value['homeWinResultMin'],
        'homeWinResultMax': value['homeWinResultMax'],
        'drawTendencyMin': value['drawTendencyMin'],
        'drawTendencyMax': value['drawTendencyMax'],
        'drawResultMin': value['drawResultMin'],
        'drawResultMax': value['drawResultMax'],
        'awayWinTendencyMin': value['awayWinTendencyMin'],
        'awayWinTendencyMax': value['awayWinTendencyMax'],
        'awayWinDifferenceMin': value['awayWinDifferenceMin'],
        'awayWinDifferenceMax': value['awayWinDifferenceMax'],
        'awayWinResultMin': value['awayWinResultMin'],
        'awayWinResultMax': value['awayWinResultMax'],
        'standardStrategy': value['standardStrategy'],
        'description': value['description'],
        'distinctByQuotes': value['distinctByQuotes'],
        'distinctByHomeAway': value['distinctByHomeAway'],
    };
}

