/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FixtureStatistic
 */
export interface FixtureStatistic {
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    gameCount: number;
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    wonCount: number;
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    drawCount: number;
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    lostCount: number;
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    goals1: number;
    /**
     * 
     * @type {number}
     * @memberof FixtureStatistic
     */
    goals2: number;
}

/**
 * Check if a given object implements the FixtureStatistic interface.
 */
export function instanceOfFixtureStatistic(value: object): value is FixtureStatistic {
    if (!('gameCount' in value) || value['gameCount'] === undefined) return false;
    if (!('wonCount' in value) || value['wonCount'] === undefined) return false;
    if (!('drawCount' in value) || value['drawCount'] === undefined) return false;
    if (!('lostCount' in value) || value['lostCount'] === undefined) return false;
    if (!('goals1' in value) || value['goals1'] === undefined) return false;
    if (!('goals2' in value) || value['goals2'] === undefined) return false;
    return true;
}

export function FixtureStatisticFromJSON(json: any): FixtureStatistic {
    return FixtureStatisticFromJSONTyped(json, false);
}

export function FixtureStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixtureStatistic {
    if (json == null) {
        return json;
    }
    return {
        
        'gameCount': json['gameCount'],
        'wonCount': json['wonCount'],
        'drawCount': json['drawCount'],
        'lostCount': json['lostCount'],
        'goals1': json['goals1'],
        'goals2': json['goals2'],
    };
}

export function FixtureStatisticToJSON(value?: FixtureStatistic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameCount': value['gameCount'],
        'wonCount': value['wonCount'],
        'drawCount': value['drawCount'],
        'lostCount': value['lostCount'],
        'goals1': value['goals1'],
        'goals2': value['goals2'],
    };
}

