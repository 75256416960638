/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserGroup } from './UserGroup';
import {
    UserGroupFromJSON,
    UserGroupFromJSONTyped,
    UserGroupToJSON,
} from './UserGroup';

/**
 * 
 * @export
 * @interface GroupMember
 */
export interface GroupMember {
    /**
     * 
     * @type {number}
     * @memberof GroupMember
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof GroupMember
     */
    userGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof GroupMember
     */
    groupMemberId: number;
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    userName: string;
    /**
     * 
     * @type {UserGroup}
     * @memberof GroupMember
     */
    userGroup: UserGroup;
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    state: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMember
     */
    admin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMember
     */
    defaultGroup: boolean;
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    invitationEmail?: string;
}

/**
 * Check if a given object implements the GroupMember interface.
 */
export function instanceOfGroupMember(value: object): value is GroupMember {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('userGroupId' in value) || value['userGroupId'] === undefined) return false;
    if (!('groupMemberId' in value) || value['groupMemberId'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('userGroup' in value) || value['userGroup'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('admin' in value) || value['admin'] === undefined) return false;
    if (!('defaultGroup' in value) || value['defaultGroup'] === undefined) return false;
    return true;
}

export function GroupMemberFromJSON(json: any): GroupMember {
    return GroupMemberFromJSONTyped(json, false);
}

export function GroupMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupMember {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'userGroupId': json['userGroupId'],
        'groupMemberId': json['groupMemberId'],
        'userName': json['userName'],
        'userGroup': UserGroupFromJSON(json['userGroup']),
        'state': json['state'],
        'admin': json['admin'],
        'defaultGroup': json['defaultGroup'],
        'invitationEmail': json['invitationEmail'] == null ? undefined : json['invitationEmail'],
    };
}

export function GroupMemberToJSON(value?: GroupMember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'userGroupId': value['userGroupId'],
        'groupMemberId': value['groupMemberId'],
        'userName': value['userName'],
        'userGroup': UserGroupToJSON(value['userGroup']),
        'state': value['state'],
        'admin': value['admin'],
        'defaultGroup': value['defaultGroup'],
        'invitationEmail': value['invitationEmail'],
    };
}

