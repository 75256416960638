/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupHighscore
 */
export interface GroupHighscore {
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    userGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof GroupHighscore
     */
    userGroupName: string;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    bets: number;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    averagePoints: number;
    /**
     * 
     * @type {number}
     * @memberof GroupHighscore
     */
    lastRank?: number;
}

/**
 * Check if a given object implements the GroupHighscore interface.
 */
export function instanceOfGroupHighscore(value: object): value is GroupHighscore {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userGroupId' in value) || value['userGroupId'] === undefined) return false;
    if (!('userGroupName' in value) || value['userGroupName'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('bets' in value) || value['bets'] === undefined) return false;
    if (!('averagePoints' in value) || value['averagePoints'] === undefined) return false;
    return true;
}

export function GroupHighscoreFromJSON(json: any): GroupHighscore {
    return GroupHighscoreFromJSONTyped(json, false);
}

export function GroupHighscoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupHighscore {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userGroupId': json['userGroupId'],
        'userGroupName': json['userGroupName'],
        'rank': json['rank'],
        'points': json['points'],
        'bets': json['bets'],
        'averagePoints': json['averagePoints'],
        'lastRank': json['lastRank'] == null ? undefined : json['lastRank'],
    };
}

export function GroupHighscoreToJSON(value?: GroupHighscore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userGroupId': value['userGroupId'],
        'userGroupName': value['userGroupName'],
        'rank': value['rank'],
        'points': value['points'],
        'bets': value['bets'],
        'averagePoints': value['averagePoints'],
        'lastRank': value['lastRank'],
    };
}

