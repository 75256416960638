/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RssFeedImage } from './RssFeedImage';
import {
    RssFeedImageFromJSON,
    RssFeedImageFromJSONTyped,
    RssFeedImageToJSON,
} from './RssFeedImage';

/**
 * 
 * @export
 * @interface RssEntry
 */
export interface RssEntry {
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    topLine?: string;
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    shortTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RssEntry
     */
    image?: string;
    /**
     * 
     * @type {Array<RssFeedImage>}
     * @memberof RssEntry
     */
    images?: Array<RssFeedImage>;
}

/**
 * Check if a given object implements the RssEntry interface.
 */
export function instanceOfRssEntry(value: object): value is RssEntry {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function RssEntryFromJSON(json: any): RssEntry {
    return RssEntryFromJSONTyped(json, false);
}

export function RssEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RssEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'link': json['link'] == null ? undefined : json['link'],
        'topLine': json['topLine'] == null ? undefined : json['topLine'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'image': json['image'] == null ? undefined : json['image'],
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(RssFeedImageFromJSON)),
    };
}

export function RssEntryToJSON(value?: RssEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'link': value['link'],
        'topLine': value['topLine'],
        'shortTitle': value['shortTitle'],
        'image': value['image'],
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(RssFeedImageToJSON)),
    };
}

