/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TournamentRound } from './TournamentRound';
import {
    TournamentRoundFromJSON,
    TournamentRoundFromJSONTyped,
    TournamentRoundToJSON,
} from './TournamentRound';
import type { Team } from './Team';
import {
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';
import type { Tournament } from './Tournament';
import {
    TournamentFromJSON,
    TournamentFromJSONTyped,
    TournamentToJSON,
} from './Tournament';

/**
 * 
 * @export
 * @interface Game
 */
export interface Game {
    /**
     * 
     * @type {number}
     * @memberof Game
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Game
     */
    date: Date;
    /**
     * 
     * @type {Team}
     * @memberof Game
     */
    team1: Team;
    /**
     * 
     * @type {Team}
     * @memberof Game
     */
    team2: Team;
    /**
     * 
     * @type {number}
     * @memberof Game
     */
    result1?: number;
    /**
     * 
     * @type {number}
     * @memberof Game
     */
    result2?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Game
     */
    finished: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Game
     */
    bettingAllowed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Game
     */
    text?: string;
    /**
     * 
     * @type {TournamentRound}
     * @memberof Game
     */
    tournamentRound: TournamentRound;
    /**
     * 
     * @type {Tournament}
     * @memberof Game
     */
    tournament: Tournament;
    /**
     * 
     * @type {boolean}
     * @memberof Game
     */
    hasEvents: boolean;
}

/**
 * Check if a given object implements the Game interface.
 */
export function instanceOfGame(value: object): value is Game {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('team1' in value) || value['team1'] === undefined) return false;
    if (!('team2' in value) || value['team2'] === undefined) return false;
    if (!('finished' in value) || value['finished'] === undefined) return false;
    if (!('bettingAllowed' in value) || value['bettingAllowed'] === undefined) return false;
    if (!('tournamentRound' in value) || value['tournamentRound'] === undefined) return false;
    if (!('tournament' in value) || value['tournament'] === undefined) return false;
    if (!('hasEvents' in value) || value['hasEvents'] === undefined) return false;
    return true;
}

export function GameFromJSON(json: any): Game {
    return GameFromJSONTyped(json, false);
}

export function GameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Game {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'team1': TeamFromJSON(json['team1']),
        'team2': TeamFromJSON(json['team2']),
        'result1': json['result1'] == null ? undefined : json['result1'],
        'result2': json['result2'] == null ? undefined : json['result2'],
        'finished': json['finished'],
        'bettingAllowed': json['bettingAllowed'],
        'text': json['text'] == null ? undefined : json['text'],
        'tournamentRound': TournamentRoundFromJSON(json['tournamentRound']),
        'tournament': TournamentFromJSON(json['tournament']),
        'hasEvents': json['hasEvents'],
    };
}

export function GameToJSON(value?: Game | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'date': ((value['date']).toISOString()),
        'team1': TeamToJSON(value['team1']),
        'team2': TeamToJSON(value['team2']),
        'result1': value['result1'],
        'result2': value['result2'],
        'finished': value['finished'],
        'bettingAllowed': value['bettingAllowed'],
        'text': value['text'],
        'tournamentRound': TournamentRoundToJSON(value['tournamentRound']),
        'tournament': TournamentToJSON(value['tournament']),
        'hasEvents': value['hasEvents'],
    };
}

