/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserMail,
} from '../models/index';
import {
    UserMailFromJSON,
    UserMailToJSON,
} from '../models/index';

export interface DeleteMailRequest {
    userMailId: string;
}

export interface GetReceivedMailsRequest {
    slotSize: number;
    startPos: number;
}

export interface SendMailRequest {
    message?: string;
    receiver?: string;
    subject?: string;
}

/**
 * 
 */
export class UserMailControllerApi extends runtime.BaseAPI {

    /**
     */
    async countMailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mail/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async countMails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countMailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMailRaw(requestParameters: DeleteMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['userMailId'] == null) {
            throw new runtime.RequiredError(
                'userMailId',
                'Required parameter "userMailId" was null or undefined when calling deleteMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mail/{userMailId}`.replace(`{${"userMailId"}}`, encodeURIComponent(String(requestParameters['userMailId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteMail(requestParameters: DeleteMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.deleteMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReceivedMailsRaw(requestParameters: GetReceivedMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserMail>>> {
        if (requestParameters['slotSize'] == null) {
            throw new runtime.RequiredError(
                'slotSize',
                'Required parameter "slotSize" was null or undefined when calling getReceivedMails().'
            );
        }

        if (requestParameters['startPos'] == null) {
            throw new runtime.RequiredError(
                'startPos',
                'Required parameter "startPos" was null or undefined when calling getReceivedMails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mail/{startPos}/{slotSize}`.replace(`{${"slotSize"}}`, encodeURIComponent(String(requestParameters['slotSize']))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters['startPos']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserMailFromJSON));
    }

    /**
     */
    async getReceivedMails(requestParameters: GetReceivedMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserMail>> {
        const response = await this.getReceivedMailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendMailRaw(requestParameters: SendMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserMail>>> {
        const queryParameters: any = {};

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        if (requestParameters['receiver'] != null) {
            queryParameters['receiver'] = requestParameters['receiver'];
        }

        if (requestParameters['subject'] != null) {
            queryParameters['subject'] = requestParameters['subject'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserMailFromJSON));
    }

    /**
     */
    async sendMail(requestParameters: SendMailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserMail>> {
        const response = await this.sendMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
