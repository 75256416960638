/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BetCountStatistic
 */
export interface BetCountStatistic {
    /**
     * 
     * @type {string}
     * @memberof BetCountStatistic
     */
    tournamentShortName: string;
    /**
     * 
     * @type {string}
     * @memberof BetCountStatistic
     */
    clientName: string;
    /**
     * 
     * @type {number}
     * @memberof BetCountStatistic
     */
    tournamentRoundNumber: number;
    /**
     * 
     * @type {number}
     * @memberof BetCountStatistic
     */
    betCount: number;
}

/**
 * Check if a given object implements the BetCountStatistic interface.
 */
export function instanceOfBetCountStatistic(value: object): value is BetCountStatistic {
    if (!('tournamentShortName' in value) || value['tournamentShortName'] === undefined) return false;
    if (!('clientName' in value) || value['clientName'] === undefined) return false;
    if (!('tournamentRoundNumber' in value) || value['tournamentRoundNumber'] === undefined) return false;
    if (!('betCount' in value) || value['betCount'] === undefined) return false;
    return true;
}

export function BetCountStatisticFromJSON(json: any): BetCountStatistic {
    return BetCountStatisticFromJSONTyped(json, false);
}

export function BetCountStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): BetCountStatistic {
    if (json == null) {
        return json;
    }
    return {
        
        'tournamentShortName': json['tournamentShortName'],
        'clientName': json['clientName'],
        'tournamentRoundNumber': json['tournamentRoundNumber'],
        'betCount': json['betCount'],
    };
}

export function BetCountStatisticToJSON(value?: BetCountStatistic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tournamentShortName': value['tournamentShortName'],
        'clientName': value['clientName'],
        'tournamentRoundNumber': value['tournamentRoundNumber'],
        'betCount': value['betCount'],
    };
}

