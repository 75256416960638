/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GroupMember,
  UserGroup,
  UserGroupDetail,
} from '../models/index';
import {
    GroupMemberFromJSON,
    GroupMemberToJSON,
    UserGroupFromJSON,
    UserGroupToJSON,
    UserGroupDetailFromJSON,
    UserGroupDetailToJSON,
} from '../models/index';

export interface AcceptUserGroupInvitationRequest {
    ugId: number;
}

export interface CountUserGroupsRequest {
    clientId: number;
    q?: string;
}

export interface CreateMembershipRequestRequest {
    ugId: number;
}

export interface CreateUserGroupRequest {
    namne?: string;
}

export interface GetDefaultRoundRequest {
    ugId: string;
}

export interface GetDetailOfUserGroupRequest {
    ugId: number;
}

export interface GetGroupMembersRequest {
    ugId: number;
}

export interface GetUserGroupsParticipatingRequest {
    tId: string;
}

export interface IsAdminRequest {
    ugId: number;
}

export interface IsMemberRequest {
    ugId: number;
}

export interface LeaveUserGroupRequest {
    ugId: number;
}

export interface RefuseUserGroupInvitationRequest {
    ugId: number;
}

export interface SearchUserGroupsRequest {
    clientId: number;
    slotSize: number;
    startPos: number;
    q?: string;
}

export interface SetStandardUserGroupRequest {
    ugId: number;
}

export interface WithdrawMembershipRequestRequest {
    ugId: number;
}

/**
 * 
 */
export class UserGroupControllerApi extends runtime.BaseAPI {

    /**
     */
    async acceptUserGroupInvitationRaw(requestParameters: AcceptUserGroupInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling acceptUserGroupInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/accept`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async acceptUserGroupInvitation(requestParameters: AcceptUserGroupInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.acceptUserGroupInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countUserGroupsRaw(requestParameters: CountUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling countUserGroups().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/usergroup/{clientId}/search-count`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async countUserGroups(requestParameters: CountUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countUserGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createMembershipRequestRaw(requestParameters: CreateMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling createMembershipRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/membership-request`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async createMembershipRequest(requestParameters: CreateMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.createMembershipRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserGroupRaw(requestParameters: CreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        const queryParameters: any = {};

        if (requestParameters['namne'] != null) {
            queryParameters['namne'] = requestParameters['namne'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async createUserGroup(requestParameters: CreateUserGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.createUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDefaultRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async getDefault(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.getDefaultRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDefaultRoundRaw(requestParameters: GetDefaultRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getDefaultRound().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/usergroup/default-round/{ugId}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getDefaultRound(requestParameters: GetDefaultRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getDefaultRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDetailOfUserGroupRaw(requestParameters: GetDetailOfUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupDetail>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getDetailOfUserGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/usergroup/{ugId}/detail`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupDetailFromJSON(jsonValue));
    }

    /**
     */
    async getDetailOfUserGroup(requestParameters: GetDetailOfUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupDetail> {
        const response = await this.getDetailOfUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupMembersRaw(requestParameters: GetGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupMember>>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getGroupMembers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/usergroup/{ugId}/member`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupMemberFromJSON));
    }

    /**
     */
    async getGroupMembers(requestParameters: GetGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupMember>> {
        const response = await this.getGroupMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyGroupMemberRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupMember>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupMemberFromJSON));
    }

    /**
     */
    async getMyGroupMember(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupMember>> {
        const response = await this.getMyGroupMemberRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupsParticipatingRaw(requestParameters: GetUserGroupsParticipatingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroup>>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getUserGroupsParticipating().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/participating/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupFromJSON));
    }

    /**
     */
    async getUserGroupsParticipating(requestParameters: GetUserGroupsParticipatingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroup>> {
        const response = await this.getUserGroupsParticipatingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isAdminRaw(requestParameters: IsAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling isAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/is-admin`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async isAdmin(requestParameters: IsAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isMemberRaw(requestParameters: IsMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling isMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/is-member`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async isMember(requestParameters: IsMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async leaveUserGroupRaw(requestParameters: LeaveUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling leaveUserGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/leave`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async leaveUserGroup(requestParameters: LeaveUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.leaveUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refuseUserGroupInvitationRaw(requestParameters: RefuseUserGroupInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling refuseUserGroupInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/refuse`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async refuseUserGroupInvitation(requestParameters: RefuseUserGroupInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.refuseUserGroupInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchUserGroupsRaw(requestParameters: SearchUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroup>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling searchUserGroups().'
            );
        }

        if (requestParameters['slotSize'] == null) {
            throw new runtime.RequiredError(
                'slotSize',
                'Required parameter "slotSize" was null or undefined when calling searchUserGroups().'
            );
        }

        if (requestParameters['startPos'] == null) {
            throw new runtime.RequiredError(
                'startPos',
                'Required parameter "startPos" was null or undefined when calling searchUserGroups().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/usergroup/{clientId}/search/{startPos}/{slotSize}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"slotSize"}}`, encodeURIComponent(String(requestParameters['slotSize']))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters['startPos']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupFromJSON));
    }

    /**
     */
    async searchUserGroups(requestParameters: SearchUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroup>> {
        const response = await this.searchUserGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setStandardUserGroupRaw(requestParameters: SetStandardUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling setStandardUserGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/standard`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async setStandardUserGroup(requestParameters: SetStandardUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.setStandardUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async withdrawMembershipRequestRaw(requestParameters: WithdrawMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling withdrawMembershipRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/{ugId}/withdraw-membership-request`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     */
    async withdrawMembershipRequest(requestParameters: WithdrawMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.withdrawMembershipRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
