/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamRanking
 */
export interface TeamRanking {
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    teamId: number;
    /**
     * 
     * @type {string}
     * @memberof TeamRanking
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamRanking
     */
    teamShortName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamRanking
     */
    teamMiniName: string;
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    goals1: number;
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    goals2: number;
    /**
     * 
     * @type {number}
     * @memberof TeamRanking
     */
    gameCount: number;
}

/**
 * Check if a given object implements the TeamRanking interface.
 */
export function instanceOfTeamRanking(value: object): value is TeamRanking {
    if (!('teamId' in value) || value['teamId'] === undefined) return false;
    if (!('teamName' in value) || value['teamName'] === undefined) return false;
    if (!('teamShortName' in value) || value['teamShortName'] === undefined) return false;
    if (!('teamMiniName' in value) || value['teamMiniName'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('goals1' in value) || value['goals1'] === undefined) return false;
    if (!('goals2' in value) || value['goals2'] === undefined) return false;
    if (!('gameCount' in value) || value['gameCount'] === undefined) return false;
    return true;
}

export function TeamRankingFromJSON(json: any): TeamRanking {
    return TeamRankingFromJSONTyped(json, false);
}

export function TeamRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamRanking {
    if (json == null) {
        return json;
    }
    return {
        
        'teamId': json['teamId'],
        'teamName': json['teamName'],
        'teamShortName': json['teamShortName'],
        'teamMiniName': json['teamMiniName'],
        'rank': json['rank'],
        'points': json['points'],
        'goals1': json['goals1'],
        'goals2': json['goals2'],
        'gameCount': json['gameCount'],
    };
}

export function TeamRankingToJSON(value?: TeamRanking | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'teamId': value['teamId'],
        'teamName': value['teamName'],
        'teamShortName': value['teamShortName'],
        'teamMiniName': value['teamMiniName'],
        'rank': value['rank'],
        'points': value['points'],
        'goals1': value['goals1'],
        'goals2': value['goals2'],
        'gameCount': value['gameCount'],
    };
}

