import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemSetPushSubscription } from '../actions';
import { pushSubscription2FuntipPushSubscription } from '../sagas';
import { userIdSelector } from '../selectors';
import { getPushNotification2Api, getSystemConfig } from '../systemConfig';

export const PushNotification: React.FC = (props) => {
    const [pushSubscription, setPushSubscription] = useState<PushSubscription | null>(null);

    const dispatch = useDispatch();

    const publicKeyQuery = useQuery({
        queryKey: ['publicKeyQuery'],
        queryFn: () => getPushNotification2Api().getPublicKey(),
        refetchOnWindowFocus: false,
    });

    const loggedInUserId = useSelector(userIdSelector);

    useEffect(() => {
        if ('Notification' in window && 'serviceWorker' in navigator && publicKeyQuery?.data?.message) {
            console.log('Service worker found');
            const tryToSubscribe = async () => {
                console.info('Trying to subscribe');
                try {
                    const registration = await navigator.serviceWorker.ready;
                    console.info('Service worker ready');

                    const subscription: PushSubscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: publicKeyQuery.data.message,
                    });
                    console.info('Push subscription: ', subscription);
                    setPushSubscription(subscription);
                } catch (e) {
                    console.error('Error subscribing: ' + JSON.stringify(e));
                }
            };
            tryToSubscribe();
        } else {
            console.log('No service worker');
        }
    }, [publicKeyQuery?.data?.message]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mutate: subscribeMutation } = useMutation({
        mutationFn: (pushSubscription: PushSubscription) =>
            getPushNotification2Api().subscribe({
                funtipPushSubscription2: pushSubscription2FuntipPushSubscription(pushSubscription!),
            }),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mutate: unsubscribeMutation } = useMutation({
        mutationFn: (endpoint: string) => getPushNotification2Api().unsubscribe({ endpoint }),
    });

    const subscribe = useCallback(
        (pushSubscription: PushSubscription) => {
            subscribeMutation(pushSubscription, {
                onSuccess: () => {
                    console.log('Subscription added');
                    dispatch(systemSetPushSubscription(pushSubscription2FuntipPushSubscription(pushSubscription!)));
                },
                onError: (err) => {
                    console.error('error adding Subscription: ' + JSON.stringify(err));
                },
            });
        },
        [dispatch, subscribeMutation],
    );

    const unsubscribe = useCallback(
        (endpoint: string) => {
            unsubscribeMutation(endpoint, {
                onSuccess: () => {
                    console.log('Unsubscribe successful');
                    dispatch(systemSetPushSubscription(undefined));
                },
                onError: (err) => {
                    console.error('error removing Subscription: ' + JSON.stringify(err));
                },
            });
        },
        [dispatch, unsubscribeMutation],
    );

    useEffect(() => {
        console.info(`PS: ${pushSubscription?.endpoint} u: ${loggedInUserId} t: ${getSystemConfig().clientName}`);
        if (pushSubscription && loggedInUserId) {
            subscribe(pushSubscription);
        }

        if (pushSubscription?.endpoint && !loggedInUserId) {
            unsubscribe(pushSubscription.endpoint);
        }
    }, [pushSubscription, loggedInUserId, dispatch, subscribe, unsubscribe]);

    return null;
};
