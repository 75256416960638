/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Highscore
 */
export interface Highscore {
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    tournamentRoundId: number;
    /**
     * 
     * @type {string}
     * @memberof Highscore
     */
    userName: string;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    absolutePos: number;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof Highscore
     */
    betCount: number;
}

/**
 * Check if a given object implements the Highscore interface.
 */
export function instanceOfHighscore(value: object): value is Highscore {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('tournamentRoundId' in value) || value['tournamentRoundId'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('absolutePos' in value) || value['absolutePos'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('betCount' in value) || value['betCount'] === undefined) return false;
    return true;
}

export function HighscoreFromJSON(json: any): Highscore {
    return HighscoreFromJSONTyped(json, false);
}

export function HighscoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Highscore {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'tournamentRoundId': json['tournamentRoundId'],
        'userName': json['userName'],
        'rank': json['rank'],
        'absolutePos': json['absolutePos'],
        'points': json['points'],
        'betCount': json['betCount'],
    };
}

export function HighscoreToJSON(value?: Highscore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userId': value['userId'],
        'tournamentRoundId': value['tournamentRoundId'],
        'userName': value['userName'],
        'rank': value['rank'],
        'absolutePos': value['absolutePos'],
        'points': value['points'],
        'betCount': value['betCount'],
    };
}

