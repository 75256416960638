/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BettingReminderText,
  RssFeed,
  TournamentRoundGroupMember,
  WeeklyComment,
} from '../models/index';
import {
    BettingReminderTextFromJSON,
    BettingReminderTextToJSON,
    RssFeedFromJSON,
    RssFeedToJSON,
    TournamentRoundGroupMemberFromJSON,
    TournamentRoundGroupMemberToJSON,
    WeeklyCommentFromJSON,
    WeeklyCommentToJSON,
} from '../models/index';

export interface GetBettingReminderTextRequest {
    tenant: string;
}

export interface GetRssFeedRequest {
    portletName: string;
    tenant: string;
}

export interface GetUserGroupHighscoreTop5Request {
    tournamentId: string;
    ugId: string;
}

export interface GetWeeklyCommentRequest {
    tenant: string;
}

export interface UpdateBettingReminderTextRequest {
    tenant: string;
    currentText?: string;
    standardText?: string;
    validDays?: number;
}

export interface UpdateWeeklyCommentRequest {
    tenant: string;
    comment?: string;
}

/**
 * 
 */
export class PortletControllerApi extends runtime.BaseAPI {

    /**
     */
    async getBettingReminderTextRaw(requestParameters: GetBettingReminderTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingReminderText>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getBettingReminderText().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portlet/betting-reminder-text/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingReminderTextFromJSON(jsonValue));
    }

    /**
     */
    async getBettingReminderText(requestParameters: GetBettingReminderTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingReminderText> {
        const response = await this.getBettingReminderTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRssFeedRaw(requestParameters: GetRssFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RssFeed>> {
        if (requestParameters['portletName'] == null) {
            throw new runtime.RequiredError(
                'portletName',
                'Required parameter "portletName" was null or undefined when calling getRssFeed().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getRssFeed().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portlet/{tenant}/{portletName}`.replace(`{${"portletName"}}`, encodeURIComponent(String(requestParameters['portletName']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RssFeedFromJSON(jsonValue));
    }

    /**
     */
    async getRssFeed(requestParameters: GetRssFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RssFeed> {
        const response = await this.getRssFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupHighscoreTop5Raw(requestParameters: GetUserGroupHighscoreTop5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentRoundGroupMember>>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getUserGroupHighscoreTop5().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getUserGroupHighscoreTop5().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portlet/highscore/{tournamentId}/{ugId}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentRoundGroupMemberFromJSON));
    }

    /**
     */
    async getUserGroupHighscoreTop5(requestParameters: GetUserGroupHighscoreTop5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentRoundGroupMember>> {
        const response = await this.getUserGroupHighscoreTop5Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWeeklyCommentRaw(requestParameters: GetWeeklyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WeeklyComment>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getWeeklyComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portlet/weekly-comment/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeeklyCommentFromJSON(jsonValue));
    }

    /**
     */
    async getWeeklyComment(requestParameters: GetWeeklyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WeeklyComment> {
        const response = await this.getWeeklyCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBettingReminderTextRaw(requestParameters: UpdateBettingReminderTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingReminderText>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling updateBettingReminderText().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['currentText'] != null) {
            queryParameters['currentText'] = requestParameters['currentText'];
        }

        if (requestParameters['standardText'] != null) {
            queryParameters['standardText'] = requestParameters['standardText'];
        }

        if (requestParameters['validDays'] != null) {
            queryParameters['validDays'] = requestParameters['validDays'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portlet/betting-reminder-text/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingReminderTextFromJSON(jsonValue));
    }

    /**
     */
    async updateBettingReminderText(requestParameters: UpdateBettingReminderTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingReminderText> {
        const response = await this.updateBettingReminderTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateWeeklyCommentRaw(requestParameters: UpdateWeeklyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WeeklyComment>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling updateWeeklyComment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['comment'] != null) {
            queryParameters['comment'] = requestParameters['comment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portlet/weekly-comment/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeeklyCommentFromJSON(jsonValue));
    }

    /**
     */
    async updateWeeklyComment(requestParameters: UpdateWeeklyCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WeeklyComment> {
        const response = await this.updateWeeklyCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
