import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { FuntipTitle } from '../../../reactutils/withTitle';
import { Game } from '../../../services/betting';
import { DateTime } from '../../DateTime';
import { getGameApi2, getSystemConfig, getTeamApi2 } from '../../System/systemConfig';
import { TeamLink, teamPathPage } from '../../TeamLink';
import { GameResult } from '../../TournamentApp/GameResult';

interface Props {}

export const TeamViewForward: FC<Props> = (props) => {
    const { teamId } = useParams();

    const navigate = useNavigate();

    const pageNumberQuery = useQuery({
        queryKey: ['teamPageNumber', teamId],
        queryFn: () => getGameApi2().getCurrentPageByTeamId({ teamId: teamId!, pageSize: 10 }),
        enabled: !!teamId,
    });

    useEffect(() => {
        if (pageNumberQuery.data) {
            navigate(`/b/team/${teamId}/${pageNumberQuery.data}/10`);
        }
    }, [navigate, pageNumberQuery.data, teamId]);

    return null;
};

const TeamViewComp: FC<Props> = (props) => {
    const { teamId, page, pageSize } = useParams();

    const [maxPage, setMaxPage] = useState(0);

    const navigate = useNavigate();

    const teamQuery = useQuery({
        queryKey: ['team', teamId],
        queryFn: () => getTeamApi2().getDetailsById({ teamId: teamId! }),
        enabled: !!teamId,
    });

    const countGamesQuery = useQuery({
        queryKey: ['countGamesByTeam', teamId],
        queryFn: () => getGameApi2().countGamesByTeamId({ teamId: teamId! }),
        enabled: !!teamId,
    });

    const gamesQuery = useQuery({
        queryKey: ['games', teamId, page, pageSize],
        queryFn: () =>
            getGameApi2().getGamesByTeamId({ teamId: teamId!, page: Number(page), pageSize: Number(pageSize) }),
        enabled: !!teamId,
    });

    useEffect(() => {
        if (countGamesQuery.data) {
            setMaxPage(Math.ceil(countGamesQuery.data / Number(pageSize)));
        } else {
            setMaxPage(0);
        }
    }, [countGamesQuery.data, pageSize]);

    const renderGame = (game: Game) => {
        return (
            <tr key={game.id}>
                <td className="d-none d-lg-table-cell">{game.tournament.name}</td>
                <td>
                    <TeamLink teamId={game.team1.id} />
                </td>
                <td>
                    <TeamLink teamId={game.team2.id} />
                </td>
                <td className="d-none d-lg-table-cell">
                    <DateTime date={game.date} format="long" />
                </td>
                <td>
                    <GameResult gameId={String(game.id)} />
                </td>
            </tr>
        );
    };

    return (
        <div>
            <FuntipTitle title="Mannschaft" subTitle={teamQuery.data?.name ?? ''} />
            <div className="d-flex justify-content-between flex-wrap">
                <div>
                    <table>
                        <tbody>
                            {teamQuery.data?.established && (
                                <tr>
                                    <td className="p-2">Gründung</td>
                                    <td className="p-2">{teamQuery.data?.established}</td>
                                </tr>
                            )}
                            {teamQuery.data?.stadium && (
                                <tr>
                                    <td className="p-2">Stadion</td>
                                    <td className="p-2">{teamQuery.data?.stadium}</td>
                                </tr>
                            )}
                            {teamQuery.data?.stadiumCapacity && (
                                <tr>
                                    <td className="p-2">Stadionkapazität</td>
                                    <td className="p-2">{teamQuery.data?.stadiumCapacity}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-end">
                        {teamQuery.data?.imageId && (
                            <img
                                src={getSystemConfig().imageUrl(teamQuery.data.imageId)}
                                alt={`Logo ${teamQuery.data?.name}`}
                                className="img-fluid me-3 mb-3"
                                width={200}
                            />
                        )}
                    </div>
                    <div className="d-flex justify-content-end">
                        {teamQuery.data?.stadiumImageId && (
                            <img
                                src={getSystemConfig().imageUrl(teamQuery.data.stadiumImageId)}
                                alt={`Stadion ${teamQuery.data?.name}`}
                                className="img-fluid me-3"
                                width={200}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Pagination>
                    <PaginationItem disabled={Number(page) <= 0} className="m-2">
                        <PaginationLink first onClick={() => navigate(teamPathPage(teamId!, 0, Number(pageSize)))} />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) <= 0} className="m-2">
                        <PaginationLink
                            previous
                            onClick={() => navigate(teamPathPage(teamId!, Number(page) - 1, Number(pageSize)))}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) >= maxPage} className="m-2">
                        <PaginationLink
                            next
                            onClick={() => navigate(teamPathPage(teamId!, Number(page) + 1, Number(pageSize)))}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) >= maxPage} className="m-2">
                        <PaginationLink
                            last
                            onClick={() => navigate(teamPathPage(teamId!, maxPage, Number(pageSize)))}
                        />
                    </PaginationItem>
                </Pagination>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th className="d-none d-lg-table-cell">Turnier</th>
                        <th>Team1</th>
                        <th>Team2</th>
                        <th className="d-none d-lg-table-cell">Datum</th>
                        <th>Ergebnis</th>
                    </tr>
                </thead>
                <tbody>{gamesQuery.data?.map((game) => renderGame(game))}</tbody>
            </Table>
        </div>
    );
};

export const TeamView = R.compose(withBorder())(TeamViewComp);
