/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameBetInfo
 */
export interface GameBetInfo {
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    gameId: number;
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    team1Id: number;
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    team2Id: number;
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    betQuoteWin?: number;
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    betQuoteDraw?: number;
    /**
     * 
     * @type {number}
     * @memberof GameBetInfo
     */
    betQuoteLose?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GameBetInfo
     */
    matchStatistics: boolean;
    /**
     * 
     * @type {string}
     * @memberof GameBetInfo
     */
    liveTickerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GameBetInfo
     */
    hasEvents: boolean;
}

/**
 * Check if a given object implements the GameBetInfo interface.
 */
export function instanceOfGameBetInfo(value: object): value is GameBetInfo {
    if (!('gameId' in value) || value['gameId'] === undefined) return false;
    if (!('team1Id' in value) || value['team1Id'] === undefined) return false;
    if (!('team2Id' in value) || value['team2Id'] === undefined) return false;
    if (!('matchStatistics' in value) || value['matchStatistics'] === undefined) return false;
    if (!('hasEvents' in value) || value['hasEvents'] === undefined) return false;
    return true;
}

export function GameBetInfoFromJSON(json: any): GameBetInfo {
    return GameBetInfoFromJSONTyped(json, false);
}

export function GameBetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameBetInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'team1Id': json['team1Id'],
        'team2Id': json['team2Id'],
        'betQuoteWin': json['betQuoteWin'] == null ? undefined : json['betQuoteWin'],
        'betQuoteDraw': json['betQuoteDraw'] == null ? undefined : json['betQuoteDraw'],
        'betQuoteLose': json['betQuoteLose'] == null ? undefined : json['betQuoteLose'],
        'matchStatistics': json['matchStatistics'],
        'liveTickerId': json['liveTickerId'] == null ? undefined : json['liveTickerId'],
        'hasEvents': json['hasEvents'],
    };
}

export function GameBetInfoToJSON(value?: GameBetInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameId': value['gameId'],
        'team1Id': value['team1Id'],
        'team2Id': value['team2Id'],
        'betQuoteWin': value['betQuoteWin'],
        'betQuoteDraw': value['betQuoteDraw'],
        'betQuoteLose': value['betQuoteLose'],
        'matchStatistics': value['matchStatistics'],
        'liveTickerId': value['liveTickerId'],
        'hasEvents': value['hasEvents'],
    };
}

