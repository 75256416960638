/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TournamentSubscription
 */
export interface TournamentSubscription {
    /**
     * 
     * @type {number}
     * @memberof TournamentSubscription
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentSubscription
     */
    tournamentId: number;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentSubscription
     */
    subscribed: boolean;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubscription
     */
    tournamentName: string;
}

/**
 * Check if a given object implements the TournamentSubscription interface.
 */
export function instanceOfTournamentSubscription(value: object): value is TournamentSubscription {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('subscribed' in value) || value['subscribed'] === undefined) return false;
    if (!('tournamentName' in value) || value['tournamentName'] === undefined) return false;
    return true;
}

export function TournamentSubscriptionFromJSON(json: any): TournamentSubscription {
    return TournamentSubscriptionFromJSONTyped(json, false);
}

export function TournamentSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'tournamentId': json['tournamentId'],
        'subscribed': json['subscribed'],
        'tournamentName': json['tournamentName'],
    };
}

export function TournamentSubscriptionToJSON(value?: TournamentSubscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'tournamentId': value['tournamentId'],
        'subscribed': value['subscribed'],
        'tournamentName': value['tournamentName'],
    };
}

