/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GroupMember,
  GroupMemberChange,
  HighscoreStrategyDescriptor,
  ResultMessage,
  UserGroupTournamentSubscription,
} from '../models/index';
import {
    GroupMemberFromJSON,
    GroupMemberToJSON,
    GroupMemberChangeFromJSON,
    GroupMemberChangeToJSON,
    HighscoreStrategyDescriptorFromJSON,
    HighscoreStrategyDescriptorToJSON,
    ResultMessageFromJSON,
    ResultMessageToJSON,
    UserGroupTournamentSubscriptionFromJSON,
    UserGroupTournamentSubscriptionToJSON,
} from '../models/index';

export interface AcceptMembershipRequestRequest {
    uId: number;
    ugId: number;
}

export interface DeleteImageRequest {
    ugId: number;
}

export interface DeleteUserGroupRequest {
    ugId: number;
}

export interface GetAvailableStandardHighscoreDescriptorsRequest {
    ugId: number;
}

export interface GetGroupMemberRequest {
    ugId: string;
}

export interface GetGroupMemberChangesRequest {
    ugId: number;
}

export interface GetHighscoreDescriptorRequest {
    tId: number;
    ugId: number;
}

export interface GetUserGroupTournamentSubscriptionRequest {
    tournamentId: string;
    ugId: string;
}

export interface GetUserGroupTournamentSubscriptionsRequest {
    ugId: string;
}

export interface GrantAdminRoleRequest {
    uId: number;
    ugId: number;
}

export interface InviteUserRequest {
    ugId: number;
    userNameOrEmail?: string;
}

export interface RefuseMembershipRequestRequest {
    uId: number;
    ugId: number;
}

export interface ReinviteUserRequest {
    uId: number;
    ugId: number;
}

export interface RemoveInvitationRequest {
    uId: number;
    ugId: number;
}

export interface RemoveMemberRequest {
    uId: number;
    ugId: number;
}

export interface RevokeAdminRoleRequest {
    uId: number;
    ugId: number;
}

export interface SetImageRequest {
    imageId: string;
    ugId: number;
}

export interface SubscribeUserGroupToTournamentRequest {
    tournamentId: string;
    ugId: string;
}

export interface UnsubscribeUserGroupToTournamentRequest {
    tournamentId: string;
    ugId: string;
}

export interface UpdateUserGroupCustomHighscoreDescriptorRequest {
    ugId: number;
    highscoreStrategyDescriptor?: HighscoreStrategyDescriptor;
}

export interface UpdateUserGroupDescriptionRequest {
    ugId: number;
    description?: string;
}

export interface UpdateUserGroupStandardHighscoreDescriptorRequest {
    hsdId: number;
    ugId: number;
}

/**
 * 
 */
export class UserGroupAdminControllerApi extends runtime.BaseAPI {

    /**
     */
    async acceptMembershipRequestRaw(requestParameters: AcceptMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling acceptMembershipRequest().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling acceptMembershipRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/accept-membership-request/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async acceptMembershipRequest(requestParameters: AcceptMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.acceptMembershipRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteImageRaw(requestParameters: DeleteImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling deleteImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/image`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async deleteImage(requestParameters: DeleteImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.deleteImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserGroupRaw(requestParameters: DeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling deleteUserGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async deleteUserGroup(requestParameters: DeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.deleteUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableStandardHighscoreDescriptorsRaw(requestParameters: GetAvailableStandardHighscoreDescriptorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HighscoreStrategyDescriptor>>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getAvailableStandardHighscoreDescriptors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/highscore-descriptor/available-standard`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HighscoreStrategyDescriptorFromJSON));
    }

    /**
     */
    async getAvailableStandardHighscoreDescriptors(requestParameters: GetAvailableStandardHighscoreDescriptorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HighscoreStrategyDescriptor>> {
        const response = await this.getAvailableStandardHighscoreDescriptorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupMemberRaw(requestParameters: GetGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupMember>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getGroupMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/member`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupMemberFromJSON(jsonValue));
    }

    /**
     */
    async getGroupMember(requestParameters: GetGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupMember> {
        const response = await this.getGroupMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupMemberChangesRaw(requestParameters: GetGroupMemberChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupMemberChange>>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getGroupMemberChanges().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/group-member-changes`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupMemberChangeFromJSON));
    }

    /**
     */
    async getGroupMemberChanges(requestParameters: GetGroupMemberChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupMemberChange>> {
        const response = await this.getGroupMemberChangesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoreDescriptorRaw(requestParameters: GetHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HighscoreStrategyDescriptor>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getHighscoreDescriptor().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getHighscoreDescriptor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/highscore-descriptor/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HighscoreStrategyDescriptorFromJSON(jsonValue));
    }

    /**
     */
    async getHighscoreDescriptor(requestParameters: GetHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HighscoreStrategyDescriptor> {
        const response = await this.getHighscoreDescriptorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupTournamentSubscriptionRaw(requestParameters: GetUserGroupTournamentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupTournamentSubscription>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getUserGroupTournamentSubscription().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getUserGroupTournamentSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/tournaments/{tournamentId}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupTournamentSubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async getUserGroupTournamentSubscription(requestParameters: GetUserGroupTournamentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupTournamentSubscription> {
        const response = await this.getUserGroupTournamentSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupTournamentSubscriptionsRaw(requestParameters: GetUserGroupTournamentSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupTournamentSubscription>>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getUserGroupTournamentSubscriptions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/tournaments`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupTournamentSubscriptionFromJSON));
    }

    /**
     */
    async getUserGroupTournamentSubscriptions(requestParameters: GetUserGroupTournamentSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupTournamentSubscription>> {
        const response = await this.getUserGroupTournamentSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async grantAdminRoleRaw(requestParameters: GrantAdminRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling grantAdminRole().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling grantAdminRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/grant-admin-role/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async grantAdminRole(requestParameters: GrantAdminRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.grantAdminRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inviteUserRaw(requestParameters: InviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling inviteUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userNameOrEmail'] != null) {
            queryParameters['userNameOrEmail'] = requestParameters['userNameOrEmail'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/invite`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async inviteUser(requestParameters: InviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.inviteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refuseMembershipRequestRaw(requestParameters: RefuseMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling refuseMembershipRequest().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling refuseMembershipRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/refuse-membership-request/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async refuseMembershipRequest(requestParameters: RefuseMembershipRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.refuseMembershipRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reinviteUserRaw(requestParameters: ReinviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling reinviteUser().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling reinviteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/reinvite/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async reinviteUser(requestParameters: ReinviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.reinviteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeInvitationRaw(requestParameters: RemoveInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling removeInvitation().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling removeInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/invitation/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async removeInvitation(requestParameters: RemoveInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.removeInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeMemberRaw(requestParameters: RemoveMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling removeMember().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling removeMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/member/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async removeMember(requestParameters: RemoveMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.removeMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async revokeAdminRoleRaw(requestParameters: RevokeAdminRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['uId'] == null) {
            throw new runtime.RequiredError(
                'uId',
                'Required parameter "uId" was null or undefined when calling revokeAdminRole().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling revokeAdminRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/revoke-admin-role/{uId}`.replace(`{${"uId"}}`, encodeURIComponent(String(requestParameters['uId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async revokeAdminRole(requestParameters: RevokeAdminRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.revokeAdminRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setImageRaw(requestParameters: SetImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['imageId'] == null) {
            throw new runtime.RequiredError(
                'imageId',
                'Required parameter "imageId" was null or undefined when calling setImage().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling setImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/image/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters['imageId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async setImage(requestParameters: SetImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.setImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeUserGroupToTournamentRaw(requestParameters: SubscribeUserGroupToTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling subscribeUserGroupToTournament().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling subscribeUserGroupToTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/tournaments/{tournamentId}/subscribe`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async subscribeUserGroupToTournament(requestParameters: SubscribeUserGroupToTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.subscribeUserGroupToTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unsubscribeUserGroupToTournamentRaw(requestParameters: UnsubscribeUserGroupToTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling unsubscribeUserGroupToTournament().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling unsubscribeUserGroupToTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/tournaments/{tournamentId}/unsubscribe`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async unsubscribeUserGroupToTournament(requestParameters: UnsubscribeUserGroupToTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.unsubscribeUserGroupToTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserGroupCustomHighscoreDescriptorRaw(requestParameters: UpdateUserGroupCustomHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HighscoreStrategyDescriptor>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling updateUserGroupCustomHighscoreDescriptor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/highscore-descriptor/custom`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HighscoreStrategyDescriptorToJSON(requestParameters['highscoreStrategyDescriptor']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HighscoreStrategyDescriptorFromJSON(jsonValue));
    }

    /**
     */
    async updateUserGroupCustomHighscoreDescriptor(requestParameters: UpdateUserGroupCustomHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HighscoreStrategyDescriptor> {
        const response = await this.updateUserGroupCustomHighscoreDescriptorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserGroupDescriptionRaw(requestParameters: UpdateUserGroupDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling updateUserGroupDescription().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/description`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async updateUserGroupDescription(requestParameters: UpdateUserGroupDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.updateUserGroupDescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserGroupStandardHighscoreDescriptorRaw(requestParameters: UpdateUserGroupStandardHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HighscoreStrategyDescriptor>> {
        if (requestParameters['hsdId'] == null) {
            throw new runtime.RequiredError(
                'hsdId',
                'Required parameter "hsdId" was null or undefined when calling updateUserGroupStandardHighscoreDescriptor().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling updateUserGroupStandardHighscoreDescriptor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/usergroup/admin/{ugId}/highscore-descriptor/standard/{hsdId}`.replace(`{${"hsdId"}}`, encodeURIComponent(String(requestParameters['hsdId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HighscoreStrategyDescriptorFromJSON(jsonValue));
    }

    /**
     */
    async updateUserGroupStandardHighscoreDescriptor(requestParameters: UpdateUserGroupStandardHighscoreDescriptorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HighscoreStrategyDescriptor> {
        const response = await this.updateUserGroupStandardHighscoreDescriptorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
